.toggle {
  border: 0;
  padding: 0.6em;
  margin-right: -0.6em;
  background: none;
  cursor: pointer;
  color: var(--colors-medium-dark);
}

.toggle:hover,
.toggle:focus-visible {
  color: var(--colors-warning);
}

/* hacky way to avoid flashing icon for a few milliseconds on initial render */
.toggle > .sun,
[data-theme="dark"] .toggle > .moon {
  display: inline-block;
}

/* stylelint-disable-next-line no-descending-specificity */
.toggle > .moon,
[data-theme="dark"] .toggle > .sun {
  display: none;
}
