.link {
  display: inline-block;
  color: var(--colors-medium-dark) !important;
  padding: 0.6em !important;
}

/* indicate active page/section */
.link.current {
  margin-bottom: -0.2em;
  border-bottom: 0.2em solid var(--colors-link-underline);
}

.link:not(.current):hover,
.link:not(.current):focus-visible {
  margin-bottom: -0.2em;
  border-bottom: 0.2em solid var(--colors-kinda-light);
}

.icon {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  vertical-align: -0.3em;
}

.label {
  font-size: 0.925em;
  font-weight: 500;
  letter-spacing: 0.025em;
  margin-left: 0.7em;
}

@media (max-width: 768px) {
  .icon {
    width: 1.8em;
    height: 1.8em;
  }

  .label {
    display: none;
  }
}
