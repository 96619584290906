:root {
  --colors-background-inner: #ffffff;
  --colors-background-outer: #fcfcfc;
  --colors-background-header: rgb(252 252 252 / 70%);
  --colors-text: #202020;
  --colors-medium-dark: #515151;
  --colors-medium: #5e5e5e;
  --colors-medium-light: #757575;
  --colors-light: #d2d2d2;
  --colors-kinda-light: #e3e3e3;
  --colors-super-light: #f4f4f4;
  --colors-super-duper-light: #fbfbfb;
  --colors-link: #0e6dc2;
  --colors-link-underline: #a6c5e7;
  --colors-success: #44a248;
  --colors-error: #ff1b1b;
  --colors-warning: #f78200;
}

[data-theme="dark"] {
  --colors-background-inner: #1e1e1e;
  --colors-background-outer: #252525;
  --colors-background-header: rgb(37 37 37 / 70%);
  --colors-text: #f1f1f1;
  --colors-medium-dark: #d7d7d7;
  --colors-medium: #b1b1b1;
  --colors-medium-light: #959595;
  --colors-light: #646464;
  --colors-kinda-light: #535353;
  --colors-super-light: #272727;
  --colors-super-duper-light: #1f1f1f;
  --colors-link: #88c7ff;
  --colors-link-underline: #496278;
  --colors-success: #78df55;
  --colors-error: #ff5151;
  --colors-warning: #f2b702;
}
