/* accessible invisibility stuff pulled from @reach/skip-nav:
https://github.com/reach/reach-ui/blob/main/packages/skip-nav/styles.css */

.skipNav {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

.skipNav:focus {
  padding: 1rem;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 99999;
  width: auto;
  height: auto;
  clip: auto;
  background: var(--colors-super-duper-light);
  color: var(--colors-link);
  border: 2px solid var(--colors-kinda-light);
  text-decoration: underline;
}
